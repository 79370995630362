.signIn-form, .signUp-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    width: 300px;
  }
  
  .signIn-form h2, .signUp-form h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .signIn-form .error, .signUp-form .error {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .signIn-form form, .signUp-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .signIn-form input, .signUp-form input {
    font-size: 18px;
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .signIn-form .submit-button:hover, .signUp-form .submit-button:hover {
    background-color: #45a049;
  }