body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: url('assets/goohogger-left-profile-cursor.png') 0 0, auto;
}

#app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}

main {
  overflow: auto;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

footer {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
}

#goohogger-image {
  max-width: 100%;
  min-width: 26em; /* Set a minimum width */
  height: auto;
  z-index: 1;
}

#overlay-image {
  position: absolute;
  z-index: 2;
  left: 50%;
  max-width: 20%;
  height: auto;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5em;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2em;
  }
}

.image-container {
  position: relative;
  display: inline-block;
}

h3 {
  text-align: center;
}

  
.text-input {
  font-size: 1em;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

#logout-button {
  grid-column: 3;
  z-index: 3;
  justify-self: end;
  position: absolute;
  top: 5%;
  right: 3%;
  padding: 0.5em 1em;
  transform: translateY(-50%);
  font-size: 1em;
  font-weight: bold;
  border-color: black;
  border-radius: 5px;
  cursor: pointer;
  color: black;
}

#main-title {
  grid-column: 2;
}