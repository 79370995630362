.interface-panel {
  position:relative;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  min-width: 25em;
  max-height: 20em;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(40vw - 40px); /* 50% of the viewport width minus 40px for padding */
  height: auto;
  z-index: 2;
}


@media (max-width: 1000px) { /* Adjust this value to the breakpoint you want */
  .interface-panel {
    position: absolute;
  }
}

  
.widget-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

#toggle-auth {
  margin-top: 1em;
  border: none;
  color: blue;
  font-weight: bold;
  cursor: pointer;
}

#toggle-auth:hover {
  color: #28a8f7;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}